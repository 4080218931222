import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorkerRegistration from 'serviceWorkerRegistration'
import reportWebVitals from 'reportWebVitals'
import App from 'app'
import 'root.scss'
import { RecoilRoot } from 'recoil'
import { BrowserRouter } from 'react-router-dom'

ReactDOM.render(
  <StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  </StrictMode>,
  document.getElementById('root'),
)

serviceWorkerRegistration.register()

reportWebVitals()
